<template>
  <div class="managerBorder">

    <div>Imágenes</div>

    <v-container>

      <!-- Image browser -->
      <v-row v-if="loadedURLS.length > 0">

        <v-col
          v-for="(img, idx) in loadedURLS"
          :key="idx"
          class="d-flex child-flex"
          cols="5"
        >
          <v-img
            :src="`${img}`"
            aspect-ratio="1"
            class="grey lighten-2"
          >

            <v-btn
              fab
              x-small
              class="red--text ml-1 mt-1"
              @click="removeImage(img)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>

            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center" >
                <v-progress-circular indeterminate color="grey lighten-5" />
              </v-row>
            </template>
            
          </v-img>
        </v-col>

      </v-row>
      
      <v-row v-else>
        <v-col align="center">
          <v-chip>
            <v-icon>mdi-image-off</v-icon>
            No hay imágenes cargadas
          </v-chip>
        </v-col>
      </v-row>

    </v-container>

    <!-- New file handler -->
    <input ref="newFile" type="file" @change="onUploadedFile" accept="image/png, image/jpeg" multiple hidden>

    <v-row>
      <v-col align="right">
        <v-btn @click="newFile()" color="success" small>
          <v-icon left>mdi-upload</v-icon>
          Subir una imagen
        </v-btn>
      </v-col>
    </v-row>

  </div>
</template>

<script>
export default {
  name: 'ManageImages',
  props: {
    loadedURLS: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    loadedURLS: {
      handler(newVal) {
        this.internalURLs = newVal
      },
      deep: true,
    },
  },
  data() {
    return {
      //data
      internalURLs: [],
    }
  },
  methods: {
    newFile() {
      this.$refs.newFile.click()
    },
    onUploadedFile(event) {
      if(!event.target.files) return

      for(let i = 0; i < event.target.files.length; i++) {
        this.internalURLs.push(URL.createObjectURL(event.target.files[i]))
      }

      this.$emit('updateImages', this.internalURLs)

    },
    removeImage(img) {
      this.internalURLs = this.internalURLs.filter(url => url !== img)
      this.$emit('updateImages', this.internalURLs)
    },
    closeDialog() { this.show = false }
  }
}
</script>

<style lang="scss" scoped>
.managerBorder {
  border: 1px solid rgba(0,0,0,.38);
  border-radius: 5px;
  padding: 5px;
}
</style>